<template>
  <div>
    <div class="upload-firmware">
      <div class="title-searchbar">
        <br />
        <h4>Đăng tải tập tin firmware</h4>
        <br />
      </div>
      <b-overlay :show="uploadOverlay" rounded="sm">
        <template #overlay>
          <div
            v-if="uploadOverlay"
            class="text-center p-4 bg-primary text-light rounded"
          >
            <b-icon
              icon="cloud-upload"
              animation="fade"
              font-scale="4"
            ></b-icon>
            <div class="mb-3">Vui lòng chờ...</div>
          </div>
        </template>
        <div class="body-upload-file">
          <br />
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-group
                id="fieldset-1"
                description="Điền các thông tin của tập tin"
              >
                <b-container fluid>
                  <b-row class="mb-2">
                    <b-col sm="6">
                      <label>Tập tin firmware</label>
                      <validation-provider
                        name="Tập tin"
                        :rules="{ required: true, ext: 'bin' }"
                        v-slot="validationContext"
                      >
                        <b-form-file
                          id="file-input"
                          v-model="firmwareFile"
                          :state="getValidationState(validationContext)"
                          placeholder="Chọn tập tin hoặc thả tập tin vào đây"
                          drop-placeholder="Thả tập tin vào đây"
                          aria-describedby="input-file-live-feedback"
                        ></b-form-file>
                        <b-form-invalid-feedback
                          id="input-file-live-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </b-col>
                    <b-col sm="3">
                      <label>Loại tập tin</label>
                      <validation-provider
                        name="Loại tập tin"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-select
                          v-model="fileTypeSelect"
                          :options="fileTypeOption"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-file-type-feedback"
                        ></b-form-select>
                        <b-form-valid-feedback id="input-file-type-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-valid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col sm="3">
                      <label>Loại thiết bị áp dụng</label>
                      <validation-provider
                        name="Loại thiết bị áp dụng"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-select
                          v-model="DeviceTypeSelect"
                          :options="DeviceTypeOption"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-device-type-feedback"
                        ></b-form-select>
                        <b-form-valid-feedback
                          id="input-device-type-feedback"
                        ></b-form-valid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="6">
                      <label id="left-label"
                        >Phiên bản firmware của tập tin</label
                      >
                      <validation-provider
                        name="Phiên bản firmware tập tin"
                        :rules="{ required: true, min: 3, max: 8, regex: /^[0-9\d.]*$/i }"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          v-model="fileFirmwareVersion"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-file-firmware-live-feedback"
                          placeholder="Phiên bản firmware của tập tin"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="input-file-firmware-live-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </b-col>
                    <b-col sm="6">
                      <label id="left-label">Các số Seri áp dụng</label>
                      <validation-provider
                        name="Số seri áp dụng"
                        :rules="{ required: true, min: 3, max: 50 }"
                        v-slot="validationContext"
                      >
                        <b-form-tags
                          input-id="tags-serial"
                          v-model="serialRequire"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-serial-require-feedback"
                          placeholder="Thêm số seri"
                        ></b-form-tags>
                        <b-form-invalid-feedback
                          id="input-serial-require-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="6">
                      <validation-provider
                        name="Phiên bản firmware áp dụng"
                        :rules="{ required: true, min: 3, max: 50 }"
                        v-slot="validationContext"
                      >
                        <label id="left-label"
                          >Các phiên bản firmware áp dụng</label
                        >
                        <b-form-tags
                          input-id="tags-firmware-version"
                          v-model="firmwareRequire"
                          placeholder="Thêm phiên bản firmware"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-firmware-require-feedback"
                        ></b-form-tags>
                        <b-form-invalid-feedback
                          id="input-firmware-require-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </b-col>
                    <b-col sm="6">
                      <label id="left-label"
                        >Các phiên bản phần cứng áp dụng</label
                      >
                      <validation-provider
                        name="Phiên bản phần cứng áp dụng"
                        :rules="{ required: true, min: 3, max: 50 }"
                        v-slot="validationContext"
                      >
                        <b-form-tags
                          input-id="tags-hardware-version"
                          v-model="hardwareRequire"
                          placeholder="Thêm phiên bản phần cứng"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-hardware-require-feedback"
                        ></b-form-tags>
                        <b-form-invalid-feedback
                          id="input-hardware-require-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="12" id="upload-firmware-btn">
                      <b-button type="submit" variant="primary"
                        >Đăng tải</b-button
                      >
                    </b-col>
                  </b-row>
                </b-container>
              </b-form-group>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </div>

    <div class="log-station">
      <div class="title-searchbar">
        <br />
        <h4>Trạm ghi chú</h4>
        <br />
      </div>
      <div class="user-activity-log">
        <div id="timecount-banner">
          <b-container fluid>
            <b-row>
              <b-col sm="6">
                <p>Hoạt động người dùng</p>
              </b-col>
              <b-col sm="6">
                <!-- <p id="timecount">{{ timerCount }}</p> -->
                <b-button variant="success" @click="RefreshTableUserActivity"
                  ><b-icon icon="arrow-counterclockwise"></b-icon
                ></b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-skeleton-wrapper :loading="loadingUserLogTable">
          <template #loading>
            <b-skeleton type="text"></b-skeleton>
            <br />
            <b-skeleton type="input"></b-skeleton>
            <br />
            <b-skeleton type="text"></b-skeleton>
            <br />
            <b-skeleton-table></b-skeleton-table>
            <b-skeleton type="text"></b-skeleton>
          </template>
          <b-table
            :key="tableUserLog"
            striped
            hover
            dark
            :items="UserLogItems"
            :fields="UserLogFields"
          >
            <template v-slot:cell(action_type)="data">
              <p>
                {{ data["item"]["actor"] }} - {{ data["item"]["actor_id"] }}
                <b-icon icon="caret-right-fill"></b-icon>
                <b-icon
                  v-if="data['item']['action_type'] == 'create'"
                  icon="cloud-upload-fill"
                  variant="success"
                ></b-icon>
                <b-icon
                  v-if="data['item']['action_type'] == 'modify'"
                  icon="wrench"
                  variant="warning"
                ></b-icon>
                <b-icon
                  v-if="data['item']['action_type'] == 'delete'"
                  icon="trash-fill"
                  variant="danger"
                ></b-icon>
                <b-icon
                  v-if="data['item']['action_type'] == 'lock'"
                  icon="lock-fill"
                  variant="danger"
                ></b-icon>
                <b-icon
                  v-if="data['item']['action_type'] == 'unlock'"
                  icon="unlock-fill"
                  variant="success"
                ></b-icon>
              </p>
            </template>
            <template v-slot:cell(action_on)="data">
              <p>
                <b-icon
                  v-if="
                    data['item']['action_on']['action_on_object_type'] == 'FILE'
                  "
                  icon="file-code-fill"
                ></b-icon>
                <b-icon
                  v-if="
                    data['item']['action_on']['action_on_object_type'] ==
                    'DEVICE'
                  "
                  icon="phone-landscape-fill"
                ></b-icon>
                - {{ data["item"]["action_on"]["action_on_object"] }}
              </p>
            </template>

            <template v-slot:cell(detail)="data">
              <b-button variant="info" @click="UserActivityDetail(data['item'])"
                >Chi tiết</b-button
              >
            </template>
          </b-table>
          <b-pagination
            size="md"
            :total-rows="UserLogTotalRow"
            v-model="UserLogCurrentPage"
            :per-page="UserLogPerPage"
            class="customPagination"
            @page-click="UserActivityPagination"
          ></b-pagination>
        </b-skeleton-wrapper>
      </div>
      <div class="device-request-log">
        <div id="timecount-banner">
          <b-container fluid>
            <b-row>
              <b-col sm="6">
                <p>Thiết bị gửi yêu cầu</p>
              </b-col>
              <b-col sm="6">
                <!-- <p>{{ timerCount }}</p> -->
                <b-button variant="success" @click="RefreshTableDeviceRequest"
                  ><b-icon icon="arrow-counterclockwise"></b-icon
                ></b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-skeleton-wrapper :loading="loadingDeviceLogTable">
          <template #loading>
            <b-skeleton type="text"></b-skeleton>
            <br />
            <b-skeleton type="input"></b-skeleton>
            <br />
            <b-skeleton type="text"></b-skeleton>
            <br />
            <b-skeleton-table></b-skeleton-table>
            <b-skeleton type="text"></b-skeleton>
          </template>
          <b-table
            :key="tableDeviceLog"
            striped
            hover
            dark
            :items="DeviceLogItems"
            :fields="DeviceLogFields"
          >
            <template v-slot:cell(action_type)="data">
              <p
                id="device-registry-request"
                v-if="data['item']['action_type'] == 'REGISTRY'"
              >
                {{ data["item"]["action_type"] }}
              </p>
              <p
                id="device-connect-request"
                v-if="data['item']['action_type'] == 'CONNECT'"
              >
                {{ data["item"]["action_type"] }}
              </p>
              <p
                id="device-download-request"
                v-if="data['item']['action_type'] == 'DOWNLOAD'"
              >
                {{ data["item"]["action_type"] }}
              </p>
            </template>
            <template v-slot:cell(progress_result)="data">
              <b-icon
                v-if="data['item']['progress_result']"
                icon="check-circle-fill"
                variant="success"
              ></b-icon>
              <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
            </template>
            <template v-slot:cell(detail)="data">
              <b-button
                variant="info"
                @click="DeviceRequestDetail(data['item'])"
                >Chi tiết</b-button
              >
            </template>
          </b-table>
          <b-pagination
            size="md"
            :total-rows="DeviceLogTotalRow"
            v-model="DeviceLogCurrentPage"
            :per-page="DeviceLogPerPage"
            @page-click="DeviceRequestPagination"
            class="customPagination"
          ></b-pagination>
        </b-skeleton-wrapper>
      </div>
    </div>
    <b-modal
      id="modal-scrollable-edit"
      ref="user-activity-modal"
      size="md"
      scrollable
      title="Chi tiết ghi chú"
      hide-footer
    >
      <div id="user-activity-modal-body">
        <b-container fluid>
          <b-row>
            <b-col sm="12">
              <div id="input-data">
                <p>Dữ liệu nhập vào:</p>
                <div v-html="UserActivityDetailData"></div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      id="modal-scrollable-edit"
      ref="device-request-modal"
      size="md"
      scrollable
      title="Chi tiết ghi chú"
      hide-footer
    >
      <div id="device-request-modal-body">
        <b-container fluid>
          <b-row>
            <b-col sm="12">
              <div id="input-data">
                <p>Dữ liệu gửi lên:</p>
                <div v-html="DeviceRequestDetailData"></div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
// common functions require
import CommonFunction from "../../common_function";
let { CheckToken } = CommonFunction;

import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";

extend("regex", {
  ...regex,
  message: "Chỉ bao gồm số và dấu chấm",
});

export default {
  name: "uploadFirmware",
  data() {
    return {
      tableUserLog: 0,
      UserLogItems: null,
      UserLogFields: null,
      UserLogTotalRow: null,
      UserLogCurrentPage: 1,
      UserLogPerPage: null,
      UserActivityDetailData: null,

      loadingUserLogTable: true,
      loadingDeviceLogTable: true,

      tableDeviceLog: 1,
      DeviceLogItems: null,
      DeviceLogFields: null,
      DeviceLogTotalRow: null,
      DeviceLogCurrentPage: null,
      DeviceLogPerPage: null,
      DeviceRequestDetailData: null,

      firmwareFile: null,
      fileFirmwareVersion: null,
      serialRequire: null,
      firmwareRequire: null,
      hardwareRequire: null,
      fileTypeSelect: null,
      DeviceTypeSelect: null,
      uploadOverlay: false,
      fileTypeOption: [
        {
          value: null,
          text: "Chọn loại tập tin",
        },
      ],
      DeviceTypeOption: [
        {
          value: null,
          text: "Chọn loại thiết bị áp dụng",
        },
      ],
      timerEnabled: true,
      timerCount: 30,
    };
  },
  mounted() {
    let getToken = CheckToken();
    let checkGetToken = Boolean(getToken);
    if (checkGetToken) {
      let { access_token } = getToken;
      let configHeader = {
        headers: {
          Authorization: access_token,
          "Content-Type": "multipart/form-data",
        },
      };

      this.$http
        .get(this.$store.state.UPDATE_FIRMWARE_TYPE_CATEGORY, configHeader)
        .then((response) => {
          let { file_type_array, device_type_array } = response["data"];
          this.fileTypeOption = file_type_array;
          this.DeviceTypeOption = device_type_array;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            this.$router.push("/");
          }
          console.log(err);
        });

      let firstParam = {
        page: 1,
        per_page: this.$store.state.PER_PAGE_TABLE,
      };

      let configHeaderLog = {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
        params: firstParam,
      };

      this.$http
        .get(this.$store.state.UPDATE_FIRMWARE_USER_LOGS, configHeaderLog)
        .then((response) => {
          let { fields, data, page, total_items } = response["data"];
          // get data user log
          this.UserLogTotalRow = total_items;
          this.UserLogCurrentPage = page;
          this.UserLogPerPage = this.$store.state.PER_PAGE_TABLE;
          this.UserLogFields = fields;
          this.UserLogItems = data;

          this.loadingUserLogTable = false;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            this.$router.push("/");
          }
          console.log(err);
        });

      this.$http
        .get(this.$store.state.UPDATE_FIRMWARE_DEVICE_LOGS, configHeaderLog)
        .then((response) => {
          // this.DeviceLogCurrentPage = 1;
          // this.DeviceLogPerPage = 10;

          let { fields, data, page, total_items } = response["data"];
          // get data device log
          this.DeviceLogTotalRow = total_items;
          this.DeviceLogCurrentPage = page;
          this.DeviceLogPerPage = this.$store.state.PER_PAGE_TABLE;

          this.DeviceLogFields = fields;
          this.DeviceLogItems = data;

          this.loadingDeviceLogTable = false;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            this.$router.push("/");
          }
          console.log(err);
        });
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    RefreshTableDeviceRequest() {
      this.loadingDeviceLogTable = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let firstParam = {
          page: this.DeviceLogCurrentPage,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeaderLog = {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
          params: firstParam,
        };
        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_DEVICE_LOGS, configHeaderLog)
          .then((response) => {
            // this.DeviceLogCurrentPage = 1;
            // this.DeviceLogPerPage = 10;

            let { fields, data, page, total_items } = response["data"];
            // get data device log
            this.DeviceLogTotalRow = total_items;
            this.DeviceLogCurrentPage = page;
            this.DeviceLogPerPage = this.$store.state.PER_PAGE_TABLE;

            this.DeviceLogFields = fields;
            this.DeviceLogItems = data;

            this.loadingDeviceLogTable = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
          });
      } else {
        this.$router.push("/");
      }
    },

    RefreshTableUserActivity() {
      this.loadingUserLogTable = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let firstParam = {
          page: this.UserLogCurrentPage,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeaderLog = {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
          params: firstParam,
        };

        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_USER_LOGS, configHeaderLog)
          .then((response) => {
            let { fields, data, page, total_items } = response["data"];
            // get data user log
            this.UserLogTotalRow = total_items;
            this.UserLogCurrentPage = page;
            this.UserLogPerPage = this.$store.state.PER_PAGE_TABLE;
            this.UserLogFields = fields;
            this.UserLogItems = data;

            this.loadingUserLogTable = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
          });
      } else {
        this.$router.push("/");
      }
    },

    DeviceRequestPagination(bvEvent, PagePaginate) {
      bvEvent.preventDefault();
      this.loadingDeviceLogTable = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeaderLog = {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
          params: firstParam,
        };

        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_DEVICE_LOGS, configHeaderLog)
          .then((response) => {
            // this.DeviceLogCurrentPage = 1;
            // this.DeviceLogPerPage = 10;

            let { fields, data, page, total_items } = response["data"];
            // get data device log
            this.DeviceLogTotalRow = total_items;
            this.DeviceLogCurrentPage = page;
            this.DeviceLogPerPage = this.$store.state.PER_PAGE_TABLE;

            this.DeviceLogFields = fields;
            this.DeviceLogItems = data;

            this.loadingDeviceLogTable = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
          });
      }
    },
    UserActivityPagination(bvEvent, PagePaginate) {
      bvEvent.preventDefault();
      this.loadingUserLogTable = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeaderLog = {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
          params: firstParam,
        };

        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_USER_LOGS, configHeaderLog)
          .then((response) => {
            let { fields, data, page, total_items } = response["data"];
            // get data user log
            this.UserLogTotalRow = total_items;
            this.UserLogCurrentPage = page;
            this.UserLogPerPage = this.$store.state.PER_PAGE_TABLE;
            this.UserLogFields = fields;
            this.UserLogItems = data;
            this.loadingUserLogTable = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
          });
      }
    },

    DeviceRequestDetail(item) {
      // let actionParams = item['descr']
      let deviceReqInfo = item["device_request_info"];
      let dataStrInject = `<ul>`;
      for (let i in deviceReqInfo) {
        dataStrInject += `<li>${i}: ${deviceReqInfo[i]}</li>`;
      }
      dataStrInject += "</ul>";

      this.DeviceRequestDetailData = dataStrInject;
      this.$refs["device-request-modal"].show();
    },

    UserActivityDetail(item) {
      let actionParams = item["description"]["action_param"];
      let dataStrInject = `<ul>`;
      for (let i in actionParams) {
        let stringObj = JSON.stringify(actionParams[i]);
        if (typeof actionParams[i] === "object") {
          let newUl = `<ul>`;
          let innerParam = actionParams[i];
          delete innerParam["_events"];
          delete innerParam["_writeStream"];
          for (let o in innerParam) {
            newUl += `<li>${o}: ${innerParam[o]}</li>`;
          }
          stringObj = `${newUl}</ul>`;
        }
        dataStrInject += `<li>${i}: ${stringObj}</li>`;
      }
      dataStrInject += `</ul>`;

      this.UserActivityDetailData = dataStrInject;
      this.$refs["user-activity-modal"].show();
    },

    play() {
      this.timerEnabled = true;
    },

    pause() {
      this.timerEnabled = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.uploadOverlay = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let configHeader = {
          headers: {
            Authorization: access_token,
            "Content-Type": "multipart/form-data",
          },
        };

        let readyBody = new FormData();
        readyBody.append("files", this.firmwareFile);
        let fieldData = {
          file_firmware_version: this.fileFirmwareVersion,
          firmware_version_require: this.firmwareRequire.toString(),
          hardware_version_require: this.hardwareRequire.toString(),
          serial_require: this.serialRequire.toString(),
          file_type: this.fileTypeSelect,
          device_type: this.DeviceTypeSelect,
        };
        readyBody.append("fields", JSON.stringify(fieldData));

        this.$http
          .post(
            this.$store.state.UPDATE_FIRMWARE_UPLOAD_FILE_URL,
            readyBody,
            configHeader
          )
          .then(() => {
            this.$swal(
              "Đăng tải thành công",
              "Đã đăng tải tập tin thành công",
              "success"
            );
            this.uploadOverlay = false;
          })
          .catch((err) => {
            let messageError = err["response"]["data"]["message"];
            this.$swal("Đăng tải thất bại", messageError, "error");
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            this.uploadOverlay = false;
          });
      } else {
        this.$router.push("/");
      }
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    // timerCount: {
    //   handler(value) {
    //     if (value > 0 && this.timerEnabled) {
    //       setTimeout(() => {
    //         this.timerCount--;
    //       }, 1000);
    //     } else {
    //       this.loadingUserLogTable = true;
    //       let getToken = CheckToken();
    //       let checkGetToken = Boolean(getToken);
    //       if (checkGetToken) {
    //         let { access_token } = getToken;
    //         let configHeaderUserLog = {
    //           headers: {
    //             Authorization: access_token,
    //             "Content-Type": "multipart/form-data",
    //           },
    //           params: { page: this.UserLogCurrentPage, per_page: this.$store.state.PER_PAGE_TABLE },
    //         };
    //         let configHeaderDeviceLog = {
    //           headers: {
    //             Authorization: access_token,
    //             "Content-Type": "application/json",
    //           },
    //           pparams: { page: this.DeviceLogCurrentPage, per_page: this.$store.state.PER_PAGE_TABLE },
    //         };

    //         this.$http
    //           .get(
    //             this.$store.state.UPDATE_FIRMWARE_USER_LOGS,
    //             configHeaderUserLog
    //           )
    //           .then((response) => {
    //             // console.log(response["data"]);
    //             this.UserLogCurrentPage = 1;
    //             this.UserLogPerPage = 10;

    //             let { items, fields, data, page, total_items } = response[
    //               "data"
    //             ];
    //             // get data user log
    //             this.UserLogTotalRow = total_items;
    //             this.UserLogCurrentPage = page;
    //             this.UserLogPerPage = items;
    //             this.UserLogFields = fields;
    //             this.UserLogItems = data;

    //             this.loadingUserLogTable = false;
    //           })
    //           .catch((err) => {
    //             if (err.response.status == 409) {
    //               this.$router.push("/");
    //             }
    //             console.log(err);
    //           });

    //         this.$http
    //           .get(
    //             this.$store.state.UPDATE_FIRMWARE_DEVICE_LOGS,
    //             configHeaderDeviceLog
    //           )
    //           .then((response) => {
    //             this.DeviceLogCurrentPage = 1;
    //             this.DeviceLogPerPage = 10;

    //             let { items, fields, data, page, total_items } = response[
    //               "data"
    //             ];
    //             // get data device log
    //             this.DeviceLogTotalRow = total_items;
    //             this.DeviceLogCurrentPage = page;
    //             this.DeviceLogPerPage = items;

    //             this.DeviceLogFields = fields;
    //             this.DeviceLogItems = data;

    //             this.loadingUserLogTable = false;
    //           })
    //           .catch((err) => {
    //             if (err.response.status == 409) {
    //               this.$router.push("/");
    //             }
    //             console.log(err);
    //           });
    //       }
    //     }
    //   },
    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
};
</script>

<style scoped>
</style>