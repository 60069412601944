<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      title="Thanh công cụ"
      bg-variant="dark"
      text-variant="light"
      backdrop
      shadow
    >
      <div id="navigator-router" class="px-3 py-2">
        <ul>
          <router-link class="router-link" :to="{ name: `listFile` }" exact
            ><li>Danh sách thiết bị</li></router-link
          >
          <router-link class="router-link" :to="{ name: `uploadFile` }" exact
            ><li>Đăng tải tập tin firmware</li></router-link
          >
        </ul>
      </div>
      <div class="sidebar-info">
        <p><i>2021 - </i></p>
      </div>
      <div class="d-flex justify-content-center button-sidebar">
        <b-button class="btn btn-danger btn-lg btn-block" @click="logout"
          >Đăng xuất</b-button
        >
      </div>
    </b-sidebar>

    <div class="data-zone">
      <b-button v-b-toggle.sidebar-backdrop style="float: left"
        ><b-icon icon="list"></b-icon
      ></b-button>
      <br />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import common functions
import CommonFunction from "../common_function";
let { CheckToken } = CommonFunction;

export default {
  name: "homePage",
  methods: {
    logout() {
      let getToken = CheckToken();
      let checkGetToken = Boolean(CheckToken);

      if (checkGetToken) {
        let { access_token } = getToken;
        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_LOGOUT_BY_ICMS_URL, {
            headers: {
              Authorization: access_token,
            },
          })
          .then(() => {
            localStorage.removeItem(this.$store.state.ICMS_USER_INFO);
            this.$router.push("/");
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem(this.$store.state.ICMS_USER_INFO);
            this.$router.push("/");
          });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
</style>