import Vue from "vue";
import VueRouter from "vue-router";

/** import component pages */
import LoginPage from "../components/login.vue";
import homePage from "../components/home-page.vue";

/** home page children components */
import listFile from "../components/firmware_file_component/list-firmware-file.vue";
import uploadFirmware from "../components/firmware_file_component/upload-firmware.vue";
import axios from "axios";

/** store import */
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    meta: { title: "Đăng nhập", guest: true },
    component: LoginPage,
  },
  {
    path: "/home-page",
    name: "Home Page",
    component: homePage,
    meta: { title: "Trang chủ", requiresAuth: true },
    children: [
      {
        path: "list-file",
        name: "listFile",
        component: listFile,
        meta: { title: "Danh sách", requiresAuth: true },
      },
      {
        path: "upload-firmware",
        name: "uploadFile",
        component: uploadFirmware,
        meta: { title: "Đăng tải", requiresAuth: true },
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  saveScrollPosition: true,
  routes,
});

/** router beforeEach - navigation guard */
/** import common function */
import CommonFuction from '../common_function'
let { CheckToken } = CommonFuction
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let getToken = CheckToken()
  let checkGetToken = Boolean(getToken)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!checkGetToken) {
      localStorage.removeItem(store.state.ICMS_USER_INFO);
      next({
        path: "/",
        params: { nextURL: to.fullPath },
      });
    } else {
      let { access_token } = getToken;
      /** check get token */
      axios
        .get(store.state.UPDATE_FIRMWARE_CHECK_TOKEN_ICMS_URL, {
          headers: {
            Authorization: access_token,
          },
          params: {
            "check_status": true
          }
        })
        .then((response) => {
          if (response.status == 409) {
            /** token expired case */
            // fall back step
            localStorage.removeItem(store.state.ICMS_USER_INFO);
            next({
              path: "/",
              params: { nextURL: to.fullPath },
            });
          } else {
            next();
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem(store.state.ICMS_USER_INFO);
          next({
            path: "/",
            params: { nextURL: to.fullPath },
          });
        });
    }
  } else {
    if (!checkGetToken) {
      localStorage.removeItem(store.state.ICMS_USER_INFO);
      next()
    } else {
      next({
        path: `/home-page/list-file`
      })
    }
  }
});

export default router;
