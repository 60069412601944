import Vue from "vue";
import Vuex from "vuex";

// require("dotenv").config();

Vue.use(Vuex);

// update firmware service config
const UPDATE_FIRMWARE_SERVICE_PROTOCOL = process.env.VUE_APP_UPDATE_FIRMWARE_SERVICE_PROTOCOL,
  UPDATE_FIRMWARE_SERVICE_HOST = process.env.VUE_APP_UPDATE_FIRMWARE_SERVICE_HOST,
  UPDATE_FIRMWARE_SERVICE_PORT = process.env.VUE_APP_UPDATE_FIRMWARE_SERVICE_PORT,
  UPDATE_FIRMWARE_SERVICE_MAIN_URL = process.env.VUE_APP_UPDATE_FIRMWARE_SERVICE_MAIN_URL;

const UPDATE_FIRMWARE_SERVICE_BASE_URL =
  `${UPDATE_FIRMWARE_SERVICE_PROTOCOL}://${UPDATE_FIRMWARE_SERVICE_HOST}:${UPDATE_FIRMWARE_SERVICE_PORT}/${UPDATE_FIRMWARE_SERVICE_MAIN_URL}`;

// update firmware app config
const UPDATE_FIRMWARE_APP_PROTOCOL = process.env.VUE_APP_UPDATE_FIRMWARE_APP_PROTOCOL,
  UPDATE_FIRMWARE_APP_HOST = process.env.VUE_APP_UPDATE_FIRMWARE_APP_HOST,
  UPDATE_FIRMWARE_APP_PORT = process.env.VUE_APP_UPDATE_FIRMWARE_APP_PORT;

const UPDATE_FIRMWARE_APP_BASE_URL = `${UPDATE_FIRMWARE_APP_PROTOCOL}://${UPDATE_FIRMWARE_APP_HOST}:${UPDATE_FIRMWARE_APP_PORT}`;

// icms oauth 2 config
const ICMS_PROTOCOL = process.env.VUE_APP_ICMS_PROTOCOL,
  ICMS_HOST = process.env.VUE_APP_ICMS_HOST,
  ICMS_PORT = process.env.VUE_APP_ICMS_PORT,
  ICMS_MAIN_URL = process.env.VUE_APP_ICMS_MAIN_URL;

const ICMS_OAUTH2_BASE_URL = `${ICMS_PROTOCOL}://${ICMS_HOST}:${ICMS_PORT}/${ICMS_MAIN_URL}`;

// app authorize with icms config
const APP_CLIENT_ID = process.env.VUE_APP_CLIENT_ID,
  APP_SCOPE = process.env.VUE_APP_SCOPE;

// icms user login authorize url
// `${this.$store.state.ICMS_HOST}/oauth2/authorize/?redirect_uri=${adminRedirect}&scope=${appScope}&response_type=code&client_id=${appClientID}`;
const ICMS_AUTHORIZE_URL =
  `${ICMS_OAUTH2_BASE_URL}/authorize/?redirect_uri=${UPDATE_FIRMWARE_APP_BASE_URL}&scope=${APP_SCOPE}&response_type=code&client_id=${APP_CLIENT_ID}`;

// icms verify auth token get pair token
const ICMS_AUTH_TOKEN_URL = `${ICMS_OAUTH2_BASE_URL}/token`;

/** update firmware api service url */
const UPDATE_FIRMWARE_LOGIN_BY_ICMS_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/login-icms`;
const UPDATE_FIRMWARE_LOGOUT_BY_ICMS_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/logout-icms`;
const UPDATE_FIRMWARE_GET_LIST_FILE_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/list-file`;
const UPDATE_FIRMWARE_GET_LIST_DEVICE_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/list-device`;
const UPDATE_FIRMWARE_GET_METADATA_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/metadata`;
const UPDATE_FIRMWARE_UPDATE_METADATA_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/update-metadata`;
const UPDATE_FIRMWARE_DELETE_FILE_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/delete-file`;
const UPDATE_FIRMWARE_UPLOAD_FILE_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/upload-file`;
const UPDATE_FIRMWARE_CHECK_TOKEN_ICMS_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/check-token-icms`;
const UPDATE_FIRMWARE_DELETE_DEVICE_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/delete-device`;
const UPDATE_FIRMWARE_MODIFY_DEVICE_STATUS_URL = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/modify-device-status`;
const UPDATE_FIRMWARE_TYPE_CATEGORY = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/type-category`;
const UPDATE_FIRMWARE_USER_LOGS = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/user-logs`;
const UPDATE_FIRMWARE_DEVICE_LOGS = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/device-logs`;

// search table data
const SEARCH_TABLE_DATA = `${UPDATE_FIRMWARE_SERVICE_BASE_URL}/search`;

// default table per page data
const PER_PAGE_TABLE = 5;

export default new Vuex.Store({
  state: {
    UPDATE_FIRMWARE_SERVICE_HOST: process.env.VUE_APP_UPDATE_FIRMWARE_SERVICE_HOST,
    APP_HOST: process.env.VUE_APP_APP_HOST,

    // local storage keys name
    ICMS_USER_INFO: process.env.VUE_APP_ICMS_USER_INFO,

    // login oauth 2 icms
    ICMS_AUTHORIZE_URL: ICMS_AUTHORIZE_URL,
    ICMS_AUTH_TOKEN_URL: ICMS_AUTH_TOKEN_URL,

    APP_CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    APP_GRANT_TYPE: process.env.VUE_APP_GRANT_TYPE,

    // update firmware api service
    UPDATE_FIRMWARE_LOGIN_BY_ICMS_URL: UPDATE_FIRMWARE_LOGIN_BY_ICMS_URL,
    UPDATE_FIRMWARE_LOGOUT_BY_ICMS_URL: UPDATE_FIRMWARE_LOGOUT_BY_ICMS_URL,
    UPDATE_FIRMWARE_GET_LIST_FILE_URL: UPDATE_FIRMWARE_GET_LIST_FILE_URL,
    UPDATE_FIRMWARE_GET_LIST_DEVICE_URL: UPDATE_FIRMWARE_GET_LIST_DEVICE_URL,
    UPDATE_FIRMWARE_GET_METADATA_URL: UPDATE_FIRMWARE_GET_METADATA_URL,
    UPDATE_FIRMWARE_UPDATE_METADATA_URL: UPDATE_FIRMWARE_UPDATE_METADATA_URL,
    UPDATE_FIRMWARE_DELETE_FILE_URL: UPDATE_FIRMWARE_DELETE_FILE_URL,
    UPDATE_FIRMWARE_UPLOAD_FILE_URL: UPDATE_FIRMWARE_UPLOAD_FILE_URL,
    UPDATE_FIRMWARE_CHECK_TOKEN_ICMS_URL: UPDATE_FIRMWARE_CHECK_TOKEN_ICMS_URL,
    UPDATE_FIRMWARE_DELETE_DEVICE_URL: UPDATE_FIRMWARE_DELETE_DEVICE_URL,
    UPDATE_FIRMWARE_MODIFY_DEVICE_STATUS_URL: UPDATE_FIRMWARE_MODIFY_DEVICE_STATUS_URL,
    UPDATE_FIRMWARE_TYPE_CATEGORY: UPDATE_FIRMWARE_TYPE_CATEGORY,
    UPDATE_FIRMWARE_USER_LOGS: UPDATE_FIRMWARE_USER_LOGS,
    UPDATE_FIRMWARE_DEVICE_LOGS: UPDATE_FIRMWARE_DEVICE_LOGS,

    // search data
    SEARCH_TABLE_DATA: SEARCH_TABLE_DATA,

    // perpage table default value
    PER_PAGE_TABLE: PER_PAGE_TABLE,
  },
  mutations: {
  },
  actions: {},
  modules: {},
});
