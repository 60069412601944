<template>
  <div>
    <div class="list-table-file">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton type="text"></b-skeleton>
          <br />
          <b-skeleton type="input"></b-skeleton>
          <br />
          <b-skeleton type="text"></b-skeleton>
          <br />
          <b-skeleton-table></b-skeleton-table>
          <b-skeleton type="text"></b-skeleton>
        </template>
        <div class="title-searchbar">
          <b-container fluid>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>Tìm kiếm</b-input-group-text>
              </template>
              <b-form-input
                v-model="FileSearchFilter"
                placeholder="Nhập tên hoặc phiên bản tập tin"
                @input="FileFirmwareInputChange"
              ></b-form-input>
              <template #append>
                <b-button @click="FileSearchBtn('firmware_file')"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </template>
            </b-input-group>
          </b-container>
          <div class="search-result" v-if="FileFoundData">
            <br />
            <h4>Kết quả tìm kiếm</h4>
            <br />
            <b-table
              :key="tableFileSearch"
              striped
              hover
              dark
              :items="FileListTableItemsSearch"
              :fields="FileListTableFieldsSearch"
            >
              <template v-slot:cell(tools)="data">
                <div class="tools">
                  <b-button
                    v-b-tooltip.hover.left
                    title="Xem chi tiết"
                    variant="info"
                    @click="ViewDetail(data['item'])"
                    ><b-icon icon="eye-fill"></b-icon
                  ></b-button>
                  <b-button
                    v-b-tooltip.hover.top
                    title="Chỉnh sửa"
                    variant="warning"
                    @click="Edit(data['item'])"
                    ><b-icon icon="wrench"></b-icon
                  ></b-button>
                  <b-button
                    v-b-tooltip.hover.right
                    title="Xóa"
                    variant="danger"
                    @click="Delete(data['item'])"
                    ><b-icon icon="trash"></b-icon
                  ></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              size="md"
              :total-rows="FileTotalRowsSearch"
              v-model="FileCurrentPageSearch"
              :per-page="FilePerPageSearch"
              @page-click="FirmwareFilePaginateSearch"
              class="customPagination"
            ></b-pagination>
          </div>
          <br />
          <h4>Danh sách tập tin firmware</h4>
          <br />
        </div>
        <b-table
          :key="tableFile"
          striped
          hover
          dark
          :items="FileListTableItems"
          :fields="FileListTableFields"
        >
          <template v-slot:cell(tools)="data">
            <div class="tools">
              <b-button
                v-b-tooltip.hover.left
                title="Xem chi tiết"
                variant="info"
                @click="ViewDetail(data['item'])"
                ><b-icon icon="eye-fill"></b-icon
              ></b-button>
              <b-button
                v-b-tooltip.hover.top
                title="Chỉnh sửa"
                variant="warning"
                @click="Edit(data['item'])"
                ><b-icon icon="wrench"></b-icon
              ></b-button>
              <b-button
                v-b-tooltip.hover.right
                title="Xóa"
                variant="danger"
                @click="Delete(data['item'])"
                ><b-icon icon="trash"></b-icon
              ></b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="FileTotalRows"
          v-model="FileCurrentPage"
          :per-page="FilePerPage"
          @page-click="FirmwareFilePaginate"
          class="customPagination"
        ></b-pagination>
      </b-skeleton-wrapper>
    </div>

    <div class="list-table-device">
      <b-skeleton-wrapper :loading="loadingDeviceTable">
        <template #loading>
          <b-skeleton type="text"></b-skeleton>
          <br />
          <b-skeleton type="input"></b-skeleton>
          <br />
          <b-skeleton type="text"></b-skeleton>
          <br />
          <b-skeleton-table></b-skeleton-table>
          <b-skeleton type="text"></b-skeleton>
        </template>
        <div class="title-searchbar">
          <b-container fluid>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>Tìm kiếm</b-input-group-text>
              </template>
              <b-form-input
                v-model="DeviceRegistryFilter"
                placeholder="Nhập tên hoặc phiên bản tập tin"
                @input="DeviceRegistryInputSearch"
              ></b-form-input>
              <template #append>
                <b-button @click="FileSearchBtn('registry_device')"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </template>
            </b-input-group>
          </b-container>
          <div class="search-result" v-if="DeviceFoundData">
            <br />
            <h4>Kết quả tìm kiếm</h4>
            <br />
            <b-table
              :key="DeviceRegistrySearch"
              striped
              hover
              dark
              :items="DeviceRegistryItemsSearch"
              :fields="DeviceRegistryFieldsSearch"
            >
              <template v-slot:cell(available)="data">
                <b-icon
                  v-if="data['item']['available']"
                  icon="check-circle-fill"
                  variant="success"
                ></b-icon>
                <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
              </template>
              <template v-slot:cell(tools)="data">
                <div class="tools">
                  <b-button
                    v-if="data['item']['available']"
                    v-b-tooltip.hover.left
                    title="Khóa"
                    variant="danger"
                    @click="LockDevice(data['item'])"
                    ><b-icon icon="lock"></b-icon
                  ></b-button>
                  <b-button
                    v-if="!data['item']['available']"
                    v-b-tooltip.hover.left
                    title="Mở khóa"
                    variant="success"
                    @click="UnlockDevice(data['item'])"
                  >
                    <b-icon icon="unlock"></b-icon>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top
                    title="Xóa"
                    variant="danger"
                    @click="DeleteDevice(data['item'])"
                    ><b-icon icon="trash"></b-icon
                  ></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              size="md"
              :total-rows="DeviceRegistryRowsSearch"
              v-model="DeviceRegistryPageSearch"
              :per-page="DeviceRegistryPerPageSearch"
              @page-click="DeviceRegistryPaginateSearch"
              class="customPagination"
            ></b-pagination>
          </div>
          <br />
          <h4>Danh sách thiết bị đã đăng ký</h4>
          <br />
        </div>
        <b-table
          :key="tableDevice"
          striped
          hover
          dark
          :items="DeviceListTableItems"
          :fields="DeviceListTableFields"
        >
          <template v-slot:cell(available)="data">
            <b-icon
              v-if="data['item']['available']"
              icon="check-circle-fill"
              variant="success"
            ></b-icon>
            <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
          </template>
          <template v-slot:cell(tools)="data">
            <div class="tools">
              <b-button
                v-if="data['item']['available']"
                v-b-tooltip.hover.left
                title="Khóa"
                variant="danger"
                @click="LockDevice(data['item'])"
                ><b-icon icon="lock"></b-icon
              ></b-button>
              <b-button
                v-if="!data['item']['available']"
                v-b-tooltip.hover.left
                title="Mở khóa"
                variant="success"
                @click="UnlockDevice(data['item'])"
              >
                <b-icon icon="unlock"></b-icon>
              </b-button>
              <b-button
                v-b-tooltip.hover.top
                title="Xóa"
                variant="danger"
                @click="DeleteDevice(data['item'])"
                ><b-icon icon="trash"></b-icon
              ></b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="DeviceTotalRows"
          v-model="DeviceCurrentPage"
          :per-page="DevicePerPage"
          @page-click="DeviceRegisteredPaginate"
          class="customPagination"
        ></b-pagination>
      </b-skeleton-wrapper>
    </div>
    <b-modal
      id="modal-scrollable-edit"
      ref="file-update-modal"
      size="lg"
      scrollable
      title="Cập nhật thông tin"
      hide-footer
    >
      <b-skeleton-wrapper :loading="updateMetaLoaded">
        <template #loading>
          <div id="file-info">
            <b-skeleton type="text"></b-skeleton>
            <b-container fluid>
              <b-row>
                <b-col sm="6">
                  <b-card>
                    <b-skeleton type="text"></b-skeleton>
                    <b-skeleton type="text"></b-skeleton>
                    <b-skeleton type="text"></b-skeleton>
                  </b-card>
                </b-col>
                <b-col sm="6">
                  <b-card>
                    <b-skeleton type="text"></b-skeleton>
                    <b-skeleton type="text"></b-skeleton>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div id="update-info">
            <b-skeleton type="text"></b-skeleton>
            <b-form-group
              id="fieldset-1"
              description="Điền các thông tin của tập tin"
            >
              <b-container fluid>
                <b-row>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="input"></b-skeleton>
                      <br />
                      <b-skeleton type="input"></b-skeleton>
                    </b-card>
                  </b-col>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="input"></b-skeleton>
                      <br />
                      <b-skeleton type="input"></b-skeleton>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
              <br />
              <b-card>
                <b-skeleton type="button"></b-skeleton>
              </b-card>
            </b-form-group>
          </div>
        </template>
        <div v-if="!updateMetaLoaded">
          <div id="file-info">
            <p>Thông tin tập tin</p>
            <b-container fluid>
              <b-row>
                <b-col sm="6">
                  <p>
                    <strong>Tên tập tin: </strong
                    >{{ currentFileUpdateInfo["file_name"] }}
                  </p>
                  <p>
                    <strong>Loại tập tin: </strong
                    >{{ currentFileUpdateInfo["file_type"] }}
                  </p>
                  <p>
                    <strong>Loại thiết bị áp dụng: </strong>
                    {{ currentFileUpdateInfo["for_gateway_type"] }}
                  </p>
                </b-col>
                <b-col sm="6">
                  <p>
                    <strong>Mã MD5: </strong
                    >{{ currentFileUpdateInfo["file_md5_hash"] }}
                  </p>
                  <p>
                    <strong>Khởi tạo ngày: </strong
                    >{{ currentFileUpdateInfo["create_at"] }}
                  </p>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div id="update-info">
            <b-overlay :show="updateOverlay" rounded="sm">
              <template #overlay>
                <div
                  v-if="updateOverlay"
                  class="text-center p-4 bg-primary text-light rounded"
                >
                  <b-icon
                    icon="cloud-upload"
                    animation="fade"
                    font-scale="4"
                  ></b-icon>
                  <div class="mb-3">Vui lòng chờ...</div>
                </div>
              </template>
              <p>Thông tin cập nhật</p>
              <b-form-group
                id="fieldset-1"
                description="Điền các thông tin của tập tin"
              >
                <b-container fluid>
                  <b-row>
                    <b-col sm="6">
                      <label>Phiên bản firmware tập tin</label>
                      <b-form-input v-model="UpdateFileFirmware"></b-form-input>
                      <br />
                      <label>Phiên bản firmware áp dụng</label>
                      <b-form-tags
                        input-id="tags-firmware-version"
                        v-model="UpdateFirmwareRequire"
                      ></b-form-tags>
                    </b-col>
                    <b-col sm="6">
                      <label>Số seri áp dụng</label>
                      <b-form-tags
                        input-id="tags-serial-require"
                        v-model="UpdateSerialRequire"
                      ></b-form-tags>
                      <br />
                      <label>Phần cứhng áp dụng</label>
                      <b-form-tags
                        input-id="tags-hardware-require"
                        v-model="UpdateHardwareRequire"
                      ></b-form-tags>
                    </b-col>
                  </b-row>
                </b-container>
                <br />
                <div class="confirm-button">
                  <b-button @click="cancelUpdate">Hủy bỏ</b-button>
                  <b-button variant="success" @click="confirmUpdate"
                    >Xác nhận</b-button
                  >
                </div>
              </b-form-group>
            </b-overlay>
          </div>
        </div>
      </b-skeleton-wrapper>
      <template slot="footer">
        <b-button variant="danger">Hủy bỏ</b-button>
        <b-button variant="primary">Xác nhận</b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-scrollable"
      ref="file-info-modal"
      size="lg"
      scrollable
      title="Thông tin tập tin"
      hide-footer
    >
      <b-skeleton-wrapper :loading="metadLoaded">
        <template #loading>
          <div>
            <div id="upload-info">
              <b-skeleton type="text"></b-skeleton>
              <b-container fluid>
                <b-row>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                    </b-card>
                  </b-col>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <div id="metadata-info">
              <b-skeleton type="text"></b-skeleton>
              <b-container fluid>
                <b-row>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                    </b-card>
                  </b-col>
                  <b-col sm="6">
                    <b-card>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                      <b-skeleton type="text"></b-skeleton>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </template>

        <div v-if="!metadLoaded">
          <div id="upload-info">
            <p>Thông tin đăng tải tập tin</p>
            <b-container fluid>
              <b-row>
                <b-col sm="6">
                  <p>
                    <strong>Tên tập tin: </strong
                    >{{ currentFileInfo["file_name"] }}
                  </p>
                  <p>
                    <strong>Loại tập tin: </strong
                    >{{ currentFileInfo["file_type"] }}
                  </p>
                  <p>
                    <strong>Loại thiết bị áp dụng</strong>
                    {{ currentFileInfo["for_gateway_type"] }}
                  </p>
                </b-col>
                <b-col sm="6">
                  <p>
                    <strong>Mã md5: </strong
                    >{{ fileMetadata["metadata"]["file_md5_hash"] }}
                  </p>
                  <p>
                    <strong>Khởi tạo ngày: </strong
                    >{{ currentFileInfo["create_at"] }}
                  </p>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div id="metadata-info">
            <p>Thông tin metadata</p>
            <b-container fluid>
              <b-row>
                <b-col sm="6">
                  <h5>Cloud Storage metadata</h5>
                  <p><strong>Kind: </strong>{{ fileMetadata["kind"] }}</p>
                  <p><strong>ID: </strong>{{ fileMetadata["id"] }}</p>
                  <p><strong>Name: </strong>{{ fileMetadata["name"] }}</p>
                  <p><strong>bucket: </strong>{{ fileMetadata["bucket"] }}</p>
                  <p>
                    <strong>Generation: </strong
                    >{{ fileMetadata["generation"] }}
                  </p>
                  <p>
                    <strong>Meta Generation: </strong
                    >{{ fileMetadata["metageneration"] }}
                  </p>
                  <p>
                    <strong>Content Type: </strong
                    >{{ fileMetadata["contentType"] }}
                  </p>
                  <p>
                    <strong>Storage Class: </strong
                    >{{ fileMetadata["storageClass"] }}
                  </p>
                  <p><strong>Size: </strong>{{ fileMetadata["size"] }}</p>
                  <p>
                    <strong>Content Encoding: </strong
                    >{{ fileMetadata["contentEncoding"] }}
                  </p>
                  <p><strong>Etag: </strong>{{ fileMetadata["etag"] }}</p>
                  <p>
                    <strong>Created Time: </strong
                    >{{ fileMetadata["timeCreated"] }}
                  </p>
                  <p>
                    <strong>Updated Time: </strong>{{ fileMetadata["updated"] }}
                  </p>
                  <p>
                    <strong>Time Storage Class Updated: </strong
                    >{{ fileMetadata["timeStorageClassUpdated"] }}
                  </p>
                </b-col>
                <b-col sm="6">
                  <h5>Tập tin Metadata</h5>
                  <p>
                    <strong>Phiên bản firmware tập tin: </strong
                    >{{ fileMetadata["metadata"]["file_firmware_version"] }}
                  </p>
                  <p>
                    <strong>Số seri áp dụng: </strong
                    >{{ fileMetadata["metadata"]["serial_require"] }}
                  </p>
                  <p>
                    <strong>Phiên bản firmware áp dụng: </strong
                    >{{ fileMetadata["metadata"]["firmware_version_require"] }}
                  </p>
                  <p>
                    <strong>Phiên bản phần cứng áp dụng: </strong
                    >{{ fileMetadata["metadata"]["hardware_version_require"] }}
                  </p>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </b-skeleton-wrapper>
    </b-modal>
  </div>
</template>

<script>
// common functions require
import CommonFunction from "../../common_function";
let { CheckToken } = CommonFunction;

export default {
  name: "listFile",
  data() {
    return {
      //search data
      FileSearchFilter: null,
      FileFoundData: false,

      DeviceRegistryFilter: null,
      DeviceFoundData: false,

      // file table
      FileListTableItems: null,
      FileListTableFields: null,
      FileTotalRows: null,
      FileCurrentPage: null,
      FilePerPage: null,

      // file table search
      FileListTableItemsSearch: null,
      FileListTableFieldsSearch: null,
      FileTotalRowsSearch: null,
      FileCurrentPageSearch: null,
      FilePerPageSearch: null,

      findFile: null,
      // file  metadata
      fileMetadata: {},
      currentFileInfo: null,
      metadLoaded: true,

      // device table
      DeviceListTableItems: null,
      DeviceListTableFields: null,
      DeviceTotalRows: null,
      DeviceCurrentPage: null,
      DevicePerPage: null,
      findDevice: null,

      // device table search
      DeviceRegistryItemsSearch: null,
      DeviceRegistryFieldsSearch: null,
      DeviceRegistryRowsSearch: null,
      DeviceRegistryPageSearch: null,
      DeviceRegistryPerPageSearch: null,

      // update file metadata
      currentFileUpdateInfo: null,
      updateMetaLoaded: true,

      // loaded data
      loading: true,
      loadingDeviceTable: true,

      // update firmware metadata
      UpdateFileFirmware: null,
      UpdateFirmwareRequire: null,
      UpdateSerialRequire: null,
      UpdateHardwareRequire: null,
      updateOverlay: false,

      tableFile: 0,
      tableDevice: 1,
      tableFileSearch: 2,
      DeviceRegistrySearch: 3,

      // filter table file
      filterByFileSelect: null,
      filterByFileOption: [
        {
          value: null,
          text: "Chọn loại tập tin",
        },
      ],
      // filter
      filterByDeviceSelect: null,
      filterByDeviceOption: [
        {
          value: null,
          text: "Chọn loại tập tin",
        },
      ],
    };
  },
  mounted() {
    let getToken = CheckToken();
    let checkGetToken = Boolean(getToken);
    if (checkGetToken) {
      /** get access_token */
      let { access_token } = getToken;
      let firstParam = {
        page: 1,
        per_page: this.$store.state.PER_PAGE_TABLE,
      };

      let configHeader = {
        headers: { Authorization: access_token },
        params: firstParam,
      };

      // request for list files
      this.$http
        .get(this.$store.state.UPDATE_FIRMWARE_GET_LIST_FILE_URL, configHeader)
        .then((response) => {
          let data = response["data"];
          this.FileListTableFields = data["fields"];
          this.FileListTableItems = data["data"];
          this.FileTotalRows = data["total_items"];
          this.FilePerPage = this.$store.state.PER_PAGE_TABLE;
          this.FileCurrentPage = data["page"];

          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            this.$router.push("/");
          }
          console.log(err);
          this.loading = false;
        });

      // request for list devices
      this.$http
        .get(
          this.$store.state.UPDATE_FIRMWARE_GET_LIST_DEVICE_URL,
          configHeader
        )
        .then((response) => {
          let data = response["data"];
          this.DeviceListTableFields = data["fields"];
          this.DeviceListTableItems = data["data"];
          this.DeviceTotalRows = data["total_items"];
          this.DevicePerPage = this.$store.state.PER_PAGE_TABLE;
          this.DeviceCurrentPage = data["page"];

          this.loadingDeviceTable = false;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            this.$router.push("/");
          }
          console.log(err);
          this.loadingDeviceTable = false;
        });
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    FileFirmwareInputChange() {
      let checkValid = Boolean(this.FileSearchFilter);
      if (!checkValid) {
        this.FileFoundData = false;
      }
    },

    DeviceRegistryInputSearch() {
      let checkValid = Boolean(this.DeviceRegistryFilter);
      if (!checkValid) {
        this.DeviceFoundData = false;
      }
    },

    DeviceRegistryPaginateSearch(bvEvent, PagePaginate) {
      this.FileFoundData = false;
      bvEvent.preventDefault();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        let bodyReady = {
          filter_key: this.DeviceRegistryFilter,
          filter_table: "registry_device",
        };

        this.$http
          .post(this.$store.state.SEARCH_TABLE_DATA, bodyReady, configHeader)
          .then((response) => {
            let data = response["data"]["found_data"];
            this.DeviceRegistryItemsSearch = data["data"];
            this.DeviceRegistryFieldsSearch = this.DeviceListTableFields;
            this.DeviceRegistryRowsSearch = data["total_items"];
            this.DeviceRegistryPageSearch = data["page"];
            this.DeviceRegistryPerPageSearch = this.$store.state.PER_PAGE_TABLE;

            if (data["show_search"]) {
              this.DeviceFoundData = true;
            } else {
              this.DeviceFoundData = false;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 409) {
              this.$router.push("/");
            }
          });
      } else {
        this.$router.push("/");
      }
    },

    FirmwareFilePaginateSearch(bvEvent, PagePaginate) {
      this.FileFoundData = false;
      bvEvent.preventDefault();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        let bodyReady = {
          filter_key: this.FileSearchFilter,
          filter_table: "firmware_file",
        };

        this.$http
          .post(this.$store.state.SEARCH_TABLE_DATA, bodyReady, configHeader)
          .then((response) => {
            let data = response["data"]["found_data"];
            this.FileListTableItemsSearch = data["data"];
            this.FileListTableFieldsSearch = this.FileListTableFields;
            this.FileTotalRowsSearch = data["total_items"];
            this.FileCurrentPageSearch = data["page"];
            this.FilePerPageSearch = this.$store.state.PER_PAGE_TABLE;

            if (data["show_search"]) {
              this.FileFoundData = true;
            } else {
              this.FileFoundData = false;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 409) {
              this.$router.push("/");
            }
          });
      } else {
        this.$router.push("/");
      }
    },

    FileSearchBtn(tableSearch) {
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let verifyBlank = false,
          filterKey = null;
        switch (tableSearch) {
          case "firmware_file":
            {
              if (this.FileSearchFilter) {
                verifyBlank = true;
                filterKey = "FileSearchFilter";
              }
            }
            break;
          case "registry_device":
            {
              if (this.DeviceRegistryFilter) {
                verifyBlank = true;
                filterKey = "DeviceRegistryFilter";
              }
            }
            break;
        }

        if (verifyBlank) {
          let { access_token } = getToken;
          let firstParam = {
            page: 1,
            per_page: this.$store.state.PER_PAGE_TABLE,
          };
          let configHeader = {
            headers: { Authorization: access_token },
            params: firstParam,
          };
          let bodyReady = {
            filter_key: this[filterKey],
            filter_table: tableSearch,
          };

          this.$http
            .post(this.$store.state.SEARCH_TABLE_DATA, bodyReady, configHeader)
            .then((response) => {
              switch (tableSearch) {
                case "firmware_file":
                  {
                    this.loading = true;
                    let data = response["data"]["found_data"];
                    this.FileListTableItemsSearch = data["data"];
                    this.FileListTableFieldsSearch = this.FileListTableFields;
                    this.FileTotalRowsSearch = data["total_items"];
                    this.FileCurrentPageSearch = data["page"];
                    this.FilePerPageSearch = this.$store.state.PER_PAGE_TABLE;

                    this.loading = false;
                    if (data["show_search"]) {
                      this.FileFoundData = true;
                    } else {
                      this.FileFoundData = false;
                    }
                  }
                  break;
                case "registry_device":
                  {
                    this.loadingDeviceTable = true;
                    let data = response["data"]["found_data"];
                    this.DeviceRegistryItemsSearch = data["data"];
                    this.DeviceRegistryFieldsSearch =
                      this.DeviceListTableFields;
                    this.DeviceRegistryRowsSearch = data["total_items"];
                    this.DeviceRegistryPageSearch = data["page"];
                    this.DeviceRegistryPerPageSearch =
                      this.$store.state.PER_PAGE_TABLE;

                    this.loadingDeviceTable = false;
                    if (data["show_search"]) {
                      this.DeviceFoundData = true;
                    } else {
                      this.DeviceFoundData = false;
                    }
                  }
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.status == 409) {
                this.$router.push("/");
              } else if (err.response.status == 404) {
                this.$swal(
                  "Thông báo",
                  "Không tìm thấy thông tin với từ khóa",
                  "error"
                );
              }
            });
        } else {
          this.$swal("Thông báo", "Mục tìm kiếm không được trống", "warning");
        }
      } else {
        this.$router.push("/");
      }
    },

    DeviceRegisteredPaginate(bvEvent, PagePaginate) {
      this.loadingDeviceTable = true;
      bvEvent.preventDefault();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        // request for list devices
        this.$http
          .get(
            this.$store.state.UPDATE_FIRMWARE_GET_LIST_DEVICE_URL,
            configHeader
          )
          .then((response) => {
            let data = response["data"];
            this.DeviceListTableFields = data["fields"];
            this.DeviceListTableItems = data["data"];
            this.DeviceTotalRows = data["total_items"];
            this.DevicePerPage = this.$store.state.PER_PAGE_TABLE;
            this.DeviceCurrentPage = data["page"];

            this.loadingDeviceTable = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.loadingDeviceTable = false;
          });
      } else {
        this.$router.push("/");
      }
    },

    FirmwareFilePaginate(bvEvent, PagePaginate) {
      this.loading = true;
      bvEvent.preventDefault();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          page: PagePaginate,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        // request for list files
        this.$http
          .get(
            this.$store.state.UPDATE_FIRMWARE_GET_LIST_FILE_URL,
            configHeader
          )
          .then((response) => {
            let data = response["data"];
            this.FileListTableFields = data["fields"];
            this.FileListTableItems = data["data"];
            this.FileTotalRows = data["total_items"];
            this.FilePerPage = this.$store.state.PER_PAGE_TABLE;
            this.FileCurrentPage = data["page"];

            this.loading = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.loading = false;
          });
      } else {
        this.$router.push("/");
      }
    },

    ReloadFirmwareSearch() {
      this.FileFoundData = false;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          page: this.FileCurrentPageSearch,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        let bodyReady = {
          filter_key: this.FileSearchFilter,
          filter_table: "firmware_file",
        };

        this.$http
          .post(this.$store.state.SEARCH_TABLE_DATA, bodyReady, configHeader)
          .then((response) => {
            let data = response["data"]["found_data"];
            this.FileListTableItemsSearch = data["data"];
            this.FileListTableFieldsSearch = this.FileListTableFields;
            this.FileTotalRowsSearch = data["total_items"];
            this.FileCurrentPageSearch = data["page"];
            this.FilePerPageSearch = this.$store.state.PER_PAGE_TABLE;
            if (data["show_search"]) {
              this.FileFoundData = true;
            } else {
              this.FileFoundData = false;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 409) {
              this.$router.push("/");
            }
          });
      } else {
        this.$router.push("/");
      }
    },

    LockDevice(items) {
      this.$swal({
        title: "Bạn có chắc chắn?",
        text: "Thiết bị sẽ được bị khóa và không thể kết nối tới dịch vụ",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          let getToken = CheckToken();
          let checkGetToken = Boolean(getToken);
          if (checkGetToken) {
            let { access_token } = getToken;
            let configHeader = {
              headers: { Authorization: access_token },
            };

            let readyBody = {
              status_lock: true,
              device_serial: items["device_serial"],
            };

            this.$http
              .post(
                this.$store.state.UPDATE_FIRMWARE_MODIFY_DEVICE_STATUS_URL,
                readyBody,
                configHeader
              )
              .then(() => {
                this.$swal(
                  "Đã khóa",
                  "Thiết bị đã được khóa thành công",
                  "success"
                );
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              })
              .catch((err) => {
                if (err.response.status == 409) {
                  this.$router.push("/");
                }
                console.log(err);
                this.$swal(
                  "Tác vụ thất bại",
                  "Khóa thiết bị không thành công",
                  "error"
                );
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              });
          } else {
            this.$router.push("/");
          }
        } else {
          this.$swal("Đã hủy", "Trạng thái thiết bị vẫn được giữ", "info");
        }
      });
    },

    UnlockDevice(items) {
      this.$swal({
        title: "Bạn có chắc chắn?",
        text: "Thiết bị sẽ được mở khóa và có thể kết nối tới dịch vụ",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          let getToken = CheckToken();
          let checkGetToken = Boolean(getToken);
          if (checkGetToken) {
            let { access_token } = getToken;
            let configHeader = {
              headers: { Authorization: access_token },
            };

            let readyBody = {
              status_lock: false,
              device_serial: items["device_serial"],
            };

            this.$http
              .post(
                this.$store.state.UPDATE_FIRMWARE_MODIFY_DEVICE_STATUS_URL,
                readyBody,
                configHeader
              )
              .then(() => {
                this.$swal(
                  "Đã mở khóa",
                  "Thiết bị đã mở khóa thành công",
                  "success"
                );
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              })
              .catch((err) => {
                if (err.response.status == 409) {
                  this.$router.push("/");
                }
                console.log(err);
                this.$swal(
                  "Tác vụ thất bại",
                  "Mở khóa thiết bị không thành công",
                  "error"
                );
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              });
          } else {
            this.$router.push("/");
          }
        } else {
          this.$swal("Đã hủy", "Trạng thái thiết bị vẫn được giữ", "info");
        }
      });
    },

    ReloadDeviceSearch() {
      this.DeviceFoundData = false;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get access_token */
        let { access_token } = getToken;
        let firstParam = {
          // page: this.DeviceRegistryPageSearch,
          page: 1,
          per_page: this.$store.state.PER_PAGE_TABLE,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: firstParam,
        };

        let bodyReady = {
          filter_key: this.DeviceRegistryFilter,
          filter_table: "registry_device",
        };

        this.$http
          .post(this.$store.state.SEARCH_TABLE_DATA, bodyReady, configHeader)
          .then((response) => {
            let data = response["data"]["found_data"];
            this.DeviceRegistryItemsSearch = data["data"];
            this.DeviceRegistryFieldsSearch = this.DeviceListTableFields;
            this.DeviceRegistryRowsSearch = data["total_items"];
            this.DeviceRegistryPageSearch = data["page"];
            this.DeviceRegistryPerPageSearch = this.$store.state.PER_PAGE_TABLE;
            if (data["show_search"]) {
              this.DeviceFoundData = true;
            } else {
              this.DeviceFoundData = false;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 409) {
              this.$router.push("/");
            }
          });
      } else {
        this.$router.push("/");
      }
    },

    reloadListDevice() {
      this.loadingDeviceTable = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let pageParams = {
          page: this.DeviceCurrentPage,
          per_page: this.DevicePerPage,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: pageParams,
        };

        this.$http
          .get(
            this.$store.state.UPDATE_FIRMWARE_GET_LIST_DEVICE_URL,
            configHeader
          )
          .then((response) => {
            let data = response["data"];
            this.DeviceListTableFields = data["fields"];
            this.DeviceListTableItems = data["data"];
            this.DeviceTotalRows = data["total_items"];
            this.DevicePerPage = data["items"];

            this.loadingDeviceTable = false;
            this.tableDevice += 1;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.DeviceListTableFields = null;
            this.DeviceListTableItems = null;
            this.DeviceTotalRows = null;
            this.DevicePerPage = null;
            this.loadingDeviceTable = null;
            this.tableFile += 1;
          });
      } else {
        this.$router.push("/");
      }
    },

    reloadListData() {
      this.loading = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        /** get accecss_token */
        let { access_token } = getToken;
        let pageParams = {
          page: this.FileCurrentPage,
          per_page: this.FilePerPage,
        };

        let configHeader = {
          headers: { Authorization: access_token },
          params: pageParams,
        };

        this.$http
          .get(
            this.$store.state.UPDATE_FIRMWARE_GET_LIST_FILE_URL,
            configHeader
          )
          .then((response) => {
            let data = response["data"];
            this.FileListTableFields = data["fields"];
            this.FileListTableItems = data["data"];
            this.FileTotalRows = data["total_items"];
            this.FilePerPage = data["items"];

            this.loading = false;
            this.tableFile += 1;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.FileListTableFields = null;
            this.FileListTableItems = null;
            this.FileTotalRows = null;
            this.FilePerPage = null;
            this.loading = false;
            this.tableFile += 1;
          });
      } else {
        this.$router.push("/");
      }
    },

    ViewDetail(items) {
      this.metadLoaded = true;
      this.$refs["file-info-modal"].show();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let configHeader = {
          headers: { Authorization: access_token },
          params: {
            file_name: items["file_name"],
          },
        };
        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_GET_METADATA_URL, configHeader)
          .then((response) => {
            let data = response["data"];
            this.currentFileInfo = items;
            this.fileMetadata = data;

            this.metadLoaded = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
          });
      } else {
        this.$router.push("/");
      }
    },

    Edit(items) {
      this.updateMetaLoaded = true;
      this.$refs["file-update-modal"].show();
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        // get current metadata
        let configHeader = {
          headers: { Authorization: access_token },
          params: {
            file_name: items["file_name"],
          },
        };
        this.$http
          .get(this.$store.state.UPDATE_FIRMWARE_GET_METADATA_URL, configHeader)
          .then((response) => {
            let data = response["data"];
            this.currentFileUpdateInfo = items;

            let CurrentMetadata = data["metadata"];
            // set current metadata
            this.UpdateFileFirmware = CurrentMetadata["file_firmware_version"];

            let checkFirmwareRequireValid = Boolean(
              CurrentMetadata["firmware_version_require"]
            );
            this.UpdateFirmwareRequire = !checkFirmwareRequireValid
              ? []
              : CurrentMetadata["firmware_version_require"].split(",");
            let checkSerialRequireValid = Boolean(
              CurrentMetadata["serial_require"]
            );
            this.UpdateSerialRequire = !checkSerialRequireValid
              ? []
              : CurrentMetadata["serial_require"].split(",");
            let checkHardwareRequireValid = Boolean(
              CurrentMetadata["hardware_version_require"]
            );
            this.UpdateHardwareRequire = !checkHardwareRequireValid
              ? []
              : CurrentMetadata["hardware_version_require"].split(",");

            this.updateMetaLoaded = false;
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.$swal(
              "Tải dữ liệu thất bại",
              "Lấy dữ liệu từ máy chủ không thành công",
              "error"
            );
          });
      } else {
        this.$router.push("/");
      }
    },

    cancelUpdate() {
      this.$refs["file-update-modal"].hide();
    },

    confirmUpdate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.updateOverlay = true;
      let getToken = CheckToken();
      let checkGetToken = Boolean(getToken);
      if (checkGetToken) {
        let { access_token } = getToken;
        let configHeader = {
          headers: { Authorization: access_token },
        };

        let readyBody = {
          file_firmware_version: this.UpdateFileFirmware,
          hardware_version_require: this.UpdateHardwareRequire.toString(),
          firmware_version_require: this.UpdateFirmwareRequire.toString(),
          serial_require: this.UpdateSerialRequire.toString(),
          file_name: this.currentFileUpdateInfo["file_name"],
        };

        this.$http
          .post(
            this.$store.state.UPDATE_FIRMWARE_UPDATE_METADATA_URL,
            readyBody,
            configHeader
          )
          .then(() => {
            this.$swal(
              "Tác vụ thành công",
              "Đã cập nhật metadata của tập tin",
              "success"
            );
            this.$refs["file-update-modal"].hide();
            this.updateOverlay = false;
            this.reloadListData();
            this.ReloadFirmwareSearch();
          })
          .catch((err) => {
            if (err.response.status == 409) {
              this.$router.push("/");
            }
            console.log(err);
            this.$swal(
              "Tác vụ thất bại",
              "Không thể cập nhật metadata của tập tin",
              "error"
            );
            this.reloadListData();
            this.ReloadFirmwareSearch();
            this.$refs["file-update-modal"].hide();
            this.updateOverlay = false;
          });
      } else {
        this.$router.push("/");
      }
    },

    DeleteDevice(items) {
      this.$swal({
        title: "Bạn có chắc chắn?",
        text: "Thiết bị bị xóa sẽ không thể hoàn tác và kết nối tới dịch vụ",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          let getToken = CheckToken();
          let checkGetToken = Boolean(getToken);
          if (checkGetToken) {
            let { access_token } = getToken;
            let configHeader = {
              headers: { Authorization: access_token },
            };

            let readyBody = {
              device_serial: items["device_serial"],
            };

            this.$http
              .post(
                this.$store.state.UPDATE_FIRMWARE_DELETE_DEVICE_URL,
                readyBody,
                configHeader
              )
              .then(() => {
                this.$swal("Đã xóa", "Đã xóa thiết bị thành công", "success");
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              })
              .catch(() => {
                this.$swal(
                  "Tác vụ thất bại",
                  "Không thể xóa thiết bị",
                  "error"
                );
                this.reloadListDevice();
                this.ReloadDeviceSearch();
              });
          } else {
            this.$router.push("/");
          }
        } else {
          this.$swal("Đã hủy", "Thông tin thiết bị vẫn được giữ", "info");
        }
      });
    },

    Delete(items) {
      this.$swal({
        title: "Bạn có chắc chắn?",
        text: "Tập tin bị xóa sẽ không thể hoàn tác",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          let getToken = CheckToken();
          let checkGetToken = Boolean(getToken);
          if (checkGetToken) {
            let { access_token } = getToken;
            let configHeader = {
              headers: { Authorization: access_token },
            };

            let readyBody = {
              file_name: items["file_name"],
            };

            this.$http
              .post(
                this.$store.state.UPDATE_FIRMWARE_DELETE_FILE_URL,
                readyBody,
                configHeader
              )
              .then(() => {
                this.$swal("Đã xóa", "Đã xóa tập tin thành công", "success");
                this.reloadListData();
                this.ReloadFirmwareSearch();
              })
              .catch(() => {
                this.$swal("Tác vụ thất bại", "Không thể xóa tập tin", "error");
                this.reloadListData();
                this.ReloadFirmwareSearch();
              });
          } else {
            this.$router.push("/");
          }
        } else {
          this.$swal("Đã hủy", "Tập tin vẫn được giữ", "info");
        }
      });
    },

    findFileEvent() {
      let checkFindStr = Boolean(this.findFile);
      if (checkFindStr) {
        alert("find");
      } else {
        this.$swal(
          "Cảnh báo",
          "Nhập tên tập tin hoặc ký tự gợi nhớ tên tập tin",
          "warning"
        );
      }
    },
  },
};
</script>

<style scoped>
</style>