/** storage for common functions */
import store from './store/index'

/** router beforeEach - navigation guard */
/** check exist token */
function CheckToken() {
    let token = null;
    let getItem = localStorage.getItem(store.state.ICMS_USER_INFO);
    let checkGetItem = Boolean(getItem);
    if (checkGetItem) {
        token = JSON.parse(getItem);
    }
    // console.log({token});
    return token;
}

export default {
    CheckToken: CheckToken
}