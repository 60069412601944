<template>
  <div class="vue-tempalte">
    <b-overlay :show="redirect" rounded="sm">
      <template #overlay>
        <div
          v-if="redirect"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <b-icon icon="cloud-upload" animation="fade" font-scale="4"></b-icon>
          <div class="mb-3">Đang điều hướng. Vui lòng chờ...</div>
        </div>
      </template>
      <div class="form-login">
        <b-img id="loginLogo" :src="require('../assets/logo.png')"></b-img>
        <h4>UPDATE FIRMWARE SERVICE</h4>
        <br />
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              ><b-icon icon="person"></b-icon
            ></b-input-group-text>
          </template>
          <b-form-input v-model="loginMail" placeholder="EMail"></b-form-input>
        </b-input-group>
        <br />
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              ><b-icon icon="lock"></b-icon
            ></b-input-group-text>
          </template>
          <b-form-input
            v-model="password"
            placeholder="Mật khẩu"
          ></b-form-input>
          <template #append
            ><b-button><b-icon icon="eye"></b-icon></b-button
          ></template>
        </b-input-group>
        <br />
        <button class="btn btn-success btn-lg btn-block">Đăng nhập</button>
        <br />
        <button @click="Login()" class="btn btn-dark btn-lg btn-block">
          SolarBK ID
        </button>
        <br />
        <p><i>2021 - </i></p>
      </div>
    </b-overlay>
  </div>
</template>

<script>
// import qs from "querystring";

export default {
  data() {
    return {
      redirect: JSON.parse(localStorage.getItem("redirect_state")),
      loginMail: null,
      password: null,
    };
  },
  mounted() {
    // check if return redirect
    let AuthToken = this.$router.currentRoute.query.auth_token;
    let checkAuthToken = Boolean(AuthToken);
    if (checkAuthToken) {
      localStorage.setItem("redirect_state", true);
      // let optionAxios = {
      //   Headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      // };

      // let RequestBody = {
      //   client_id: this.$store.state.APP_CLIENT_ID,
      //   client_token: this.$store.state.APP_CLIENT_TOKEN,
      //   auth_token: AuthToken,
      //   grant_type: this.$store.state.APP_GRANT_TYPE,
      // };

      this.$http
        .post(this.$store.state.UPDATE_FIRMWARE_LOGIN_BY_ICMS_URL, {
          client_id: this.$store.state.APP_CLIENT_ID,
          auth_token: AuthToken,
          grant_type: this.$store.state.APP_GRANT_TYPE,
        })
        .then((res) => {
          // console.log(res["data"]);
          let localStore = JSON.stringify(res["data"]);
          localStorage.setItem(this.$store.state.ICMS_USER_INFO, localStore);
          this.$router.push(`/home-page/list-file`);
          localStorage.setItem("redirect_state", false);
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/");
          localStorage.setItem("redirect_state", false);
        });

      // this.$http
      //   .post(
      //     this.$store.state.ICMS_AUTH_TOKEN_URL,
      //     qs.stringify(RequestBody),
      //     optionAxios
      //   )
      //   .then((response) => {
      //     let tokenResponse = response["data"];
      //     if (response.data.error != undefined) {
      //       console.log(response.data.error);
      //       this.$router.push("/");
      //     } else {
      //       this.$http
      //         .post(this.$store.state.UPDATE_FIRMWARE_LOGIN_BY_ICMS_URL, {
      //           access_token: tokenResponse["access_token"],
      //           refresh_token: tokenResponse["refresh_token"],
      //         })
      //         .then((res) => {
      //           console.log(res["data"]);
      //           let localStore = JSON.stringify(res["data"]);
      //           localStorage.setItem(
      //             this.$store.state.ICMS_USER_INFO,
      //             localStore
      //           );
      //           this.$router.push(`/home-page/list-file`);
      //           localStorage.setItem("redirect_state", false);
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //           this.$router.push("/");
      //           localStorage.setItem("redirect_state", false);
      //         });
      //     }
      //   });
    }
  },
  methods: {
    Login() {
      localStorage.setItem("redirect_state", true);
      // let adminRedirect = `${this.$store.state.APP_HOST}`,
      //   appClientID = this.$store.state.APP_CLIENT_ID,
      //   appScope = this.$store.state.APP_SCOPE;
      window.location = this.$store.state.ICMS_AUTHORIZE_URL;
    },
  },
};
</script>
