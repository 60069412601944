import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
/** bootstrap vue import */
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";

import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate"

import vi from "vee-validate/dist/locale/vi.json"
import * as rules from "vee-validate/dist/rules"

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

import axios from "axios";

import "./assets/css/main.css";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("vi", vi);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.use(VueSweetalert2);
Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
